import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  { prop: 'dequ_no', label: '委托单号', itemType: 'input', widthAuto: true, sortable: false },
  { prop: 'cust_name', label: '客户简称', itemType: 'input', widthAuto: true, sortable: false },
  { prop: 'dequ_brief', label: '设计内容', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { prop: 'dequ_num', label: '产品个数', itemType: 'input', input: false, widthAuto: true, sortable: false },
  { prop: 'dequ_stime', label: '设计日期', itemType: 'date', widthAuto: true, formatter: val => getDateNoTime(val, true) },
  { prop: 'dequ_petime', label: '要求交稿日期', itemType: 'date', widthAuto: true, formatter: val => getDateNoTime(val, true) },
  { prop: 'dequ_stff_name', label: '委托人', itemType: 'input', widthAuto: true, sortable: false },
  { prop: 'stff_name', label: '录入人', itemType: 'input', widthAuto: true, sortable: false },
  { prop: 'create_time', label: '录入时间', itemType: 'date', widthAuto: true, formatter: val => getDateNoTime(val, true) },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const mpqStatisticsProperties = [
  { prop: 'cust_abbr', label: '客户简称', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { prop: 'stff_name', label: '委托人', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { prop: 'dept_name', label: '委托部门', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { prop: 'dequ_designer_name', label: '设计师', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { prop: 'dequ_pter_rank', label: '图稿等级', itemType: 'select', input: true, options: [], widthAuto: true, sortable: false },
  { prop: 'count', label: '数量', itemType: 'input', input: false, widthAuto: true, sortable: true },
  { prop: 'num', label: '分值', itemType: 'input', input: false, labelWidth: 120, sortable: false },
  { prop: 'sum_num', label: '总分值', itemType: 'input', input: false, widthAuto: true, sortable: true },
  { prop: 'price', label: '设计单价', itemType: 'input', input: false, widthAuto: true, sortable: false },
  { prop: 'sum_price', label: '设计费', itemType: 'input', input: false, widthAuto: true, sortable: false }
];
