import { render, staticRenderFns } from "./mpqStatisticsList.vue?vue&type=template&id=f88badd2&scoped=true&"
import script from "./mpqStatisticsList.vue?vue&type=script&lang=js&"
export * from "./mpqStatisticsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88badd2",
  null
  
)

export default component.exports