<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange1"
          :picker-options="pickerOptions"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="统计开始日期"
          end-placeholder="统计结束日期"
        />
        <el-button size="small" type="primary" icon="el-icon-search" @click="getTableData()" class="vg_mt_8">查询 </el-button>
        <el-button type="info" size="small" class="vg_mt_8" @click="buttonRefresh1()" icon="el-icon-refresh-right"> 刷新</el-button>
        <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
      </div>
      <DynamicUTable
        id="multiTable"
        ref="multiTable"
        :tableData="tableData"
        :columns="tableProperties"
        :total-page="totalPage"
        v-loading="loadFlag1"
        :page-size="50"
        :need-search="true"
        :need-check-box="false"
        :need-fixed-height="true"
        :show-summary="true"
        :summariesColumns="['count', 'sum_num', 'sum_price']"
        @getTableData="getTableData"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { dequAPI } from '@api/modules/dequ';
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { downloadFile, getDataCenterByPermId } from '@api/public';
import { mpqStatisticsProperties } from '@/views/DevelopManagement/DequManage/dequ';

export default {
  name: 'DataList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      activeName: 'first',
      dateRange1: [],
      tableProperties: cloneDeep(mpqStatisticsProperties),
      tableData: [],
      loadFlag1: true,
      totalPage: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.changeProperties();
      await this.getTableData();
    },
    async changeProperties() {
      this.tableProperties.find(({ label }) => label === '图稿等级').options = await getDataCenterByPermId({ id: 10002, type: 'select' });
    },
    getTableData() {
      this.loadFlag1 = true;
      let searchForm1 = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange1 || [];
      let start = startTime ? startTime : null;
      let end = endTime ? endTime : null;
      searchForm1.start_time = start;
      searchForm1.end_time = end;
      dequAPI.getDequCountList(searchForm1).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag1 = false;
      });
    },
    buttonRefresh1() {
      this.dateRange1 = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.multiTable.searchForm };
      dequAPI.exportGetDequCount(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: '稿费稿量统计' });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
